import React from "react"
import Card from "./style"
import { Login, CreateAccount, RecoverPassword } from "~components/Modals"
export default function PricingCard({
  backgroundColor,
  cardTitle,
  yearlyPackage,
  amount,
  ...rest
}) {
  const [modalShow, setModalShow] = React.useState(false)
  const [modalShowCreateAccount, setModalShowCreateAccount] = React.useState(
    false
  )
  const [modalShowRecoverPassword, setModalShowRecoverPassword] = React.useState(
    false
  )

  return (
    <>
      <Login
        show={modalShow}
        onHide={() => setModalShow(false)}
        setModalShowCreateAccount={setModalShowCreateAccount}
        setModalShowRecoverPassword={setModalShowRecoverPassword}
      />
      <CreateAccount
        show={modalShowCreateAccount}
        onHide={() => setModalShowCreateAccount(false)}
      />
      <RecoverPassword
        show={modalShowRecoverPassword}
        onHide={() => setModalShowRecoverPassword(false)}
      />
      <Card backgroundColor="#fff" {...rest}>
        <Card.Top backgroundColor={backgroundColor}>
          <Card.Title as="h3">{cardTitle}</Card.Title>
          <Card.Text>Suscripción mensual </Card.Text>
          <Card.Price>
            <Card.PriceText alignSelf="flex-start">$</Card.PriceText>
            <Card.Amount>{amount}</Card.Amount>
            <Card.PriceTextTwo>/ Mensual</Card.PriceTextTwo>
            <Card.currency>Pesos Mexicanos</Card.currency>
          </Card.Price>
        </Card.Top>
        <Card.List pt="40px">
          <Card.ListItem iconColor={backgroundColor}>
            Alertas de cambio de precio de una gasolinera
          </Card.ListItem>
          <Card.ListItem iconColor={backgroundColor}>Reportes</Card.ListItem>
          <Card.ListItem iconColor={backgroundColor}>
            Facturación en línea
          </Card.ListItem>
          <Card.ListItem iconColor={backgroundColor}>
            Cuenta de analista
          </Card.ListItem>
        </Card.List>
        <Card.Box>
          <Card.Button
            textColor="#fff"
            hoverTextColor="#fff"
            backgroundColor={backgroundColor}
            hoverBackgroundColor={backgroundColor}
            rounded={true}
            sizeX="180px"
            sizeY="56px"
            rounded="true"
            onClick={() => setModalShow(true)}
          >
            Iniciar sesión
          </Card.Button>
        </Card.Box>
      </Card>
    </>
  )
}
