import React from "react"
import NavDesktop from "./NavDesktop"
import NavMovil from "./NavMovil"
import useMedia from "use-media"

const index = () => {
  const isMovil = useMedia({ minWidth: "1000px" })
  return <div>{isMovil ? <NavDesktop /> : <NavMovil />}</div>
}

export default index
