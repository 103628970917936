import React, { useState } from "react"
import classNames from "classnames"
import { Images } from "~data"
import MobileNavLinks from "./MobileNavLinks"
import "./styles.scss"

const NavMovil = () => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false)

  const active = classNames("mobile-menu", {
    open: mobileNavOpen,
  })

  return (
    <>
      <header className="navigation-wrapper">
        <div>
          <div className="navigation-header">
            <span className="navigation-names">
              <a className="link containerLogo" href="/">
                <img
                  style={{ width: 35, height: 35 }}
                  src={Images.FooterSix.Logo}
                  alt="logo"
                />
                <div className="titleLogo">GasGas Analytics</div>
              </a>
            </span>
            <span className="navigation-links">
              <button
                aria-label="Toggle Mobile Menu Button"
                className={active}
                onClick={() => {
                  setMobileNavOpen(mobileNavOpen => !mobileNavOpen)
                }}
              >
                <div className="bar-one" />
                <div className="bar-two" />
                <div className="bar-three" />
              </button>
            </span>
          </div>
        </div>
      </header>
      <MobileNavLinks 
      open={mobileNavOpen} 
      setMobileNavOpen={setMobileNavOpen}
      />
    </>
  )
}

export default NavMovil
