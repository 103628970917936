import React from "react"
import { Col, Row, Container } from "react-bootstrap"
import Pricing from "./style"
import PricingCard from "./Components/Card"

export default function PricingSection({ ...rest }) {
  const [yearly, setYearly] = React.useState(true)

  return (
    <Pricing backgroundColor="#f3f4f6" {...rest}>
      <Container>
        <Pricing.Box pb="40px" pbXL="35px">
          <Row className="align-items-end justify-content-center">
            <Col xs="12" className="col-xl-8 col-lg-7">
              <Pricing.Box  className="text-center">
                <Pricing.Subtitle>Precios</Pricing.Subtitle>
                <Pricing.Title mb="20px" mbXL="0">
                  Membresia mensual
                </Pricing.Title>
              </Pricing.Box>
            </Col>
          </Row>
        </Pricing.Box>
        <Row className="justify-content-center">
          <Col xs="12" className="col-lg-4 col-md-6 col-sm-9 col-xs-10">
            <PricingCard
              cardTitle={"Alertas GasGas"}
              backgroundColor={"#ff7272"}
              amount={99}
              yearlyPackage={yearly}
            />
          </Col>
        </Row>
      </Container>
    </Pricing>
  )
}
