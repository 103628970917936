import React, { useState } from "react"
import { Container, FormControl, InputGroup, Modal } from "react-bootstrap"
import Cookies from 'js-cookie'
import axios from 'axios';
import {
  ButtonLogin,
  ModalStyle,
  ContainerModal,
  ContainerFooter,
  Text,
  ContainerHeader,
  Alert,
} from "./style"
import "./styles.scss"
import { URL_SERVER, URL_APP, DOMAIN } from "../../helpers"

const url = `${URL_SERVER}/public/user/login`;

const errorText = 'Ocurrió un error';

const Login = props => {
  const { onHide, setModalShowCreateAccount, setModalShowRecoverPassword, modalLeft = false } = props

  const cleanState = { email: null, password: null };
  const cleanError = { ok: null, message: "" };

  const [state, setState] = useState(cleanState);
  const [error, setError] = useState(cleanError);
  const [disabled, setDisabled] = useState(false);

  async function onLogin() {
    setError(cleanError);
    setDisabled(true);
    await axios.post(url, state)
    .then(({ data, status }) => {
      if (status !== 200) throw new Error(data?.statusText || errorText);
      if (!data.ok) throw new Error(data?.error?.message || errorText);
      Cookies.set('token', data?.data?.token, {
        domain: DOMAIN,
        sameSite: 'Lax',
      });
      onHide();
      window.location.href = `${URL_APP}/auth/login`;
    })
    .catch(({ message }) => {
      setError({ ok: true, message });
    });
    setDisabled(false);
  }

  return (
    <ModalStyle>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        contentClassName="ModalL"
        dialogClassName={modalLeft ? "modal-left" : ""}
      >
        <ContainerHeader>
          <span onClick={onHide}>x</span>
        </ContainerHeader>
        <Modal.Body>
          <Container>
            <div class="form-title text-center mb-4">
              <h4>Iniciar sesión</h4>
            </div>
            {
              error.ok && (
                <Alert variant="danger">
                  {error.message}
                </Alert>
              )
            }
            <InputGroup className="mb-3">
              <FormControl
                placeholder="Correo electrónico..."
                aria-label="email"
                aria-describedby="Correo electrónico"
                disabled={disabled}
                value={state.email}
                onChange={({ target: { value }}) => setState({ ...state, email: value })}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <FormControl
                placeholder="Contraseña..."
                aria-label="Contraeña"
                aria-describedby="contraseña"
                disabled={disabled}
                type="password"
                value={state.password}
                onChange={({ target: { value }}) => setState({ ...state, password: value })}
              />
            </InputGroup>
            <ContainerModal>
              <a onClick={() => setModalShowRecoverPassword(true)}>¿Olvidaste tu contraseña?</a>
              <ButtonLogin
                disabled={disabled}
                loading={disabled}
                className="btn-green"
                onClick={onLogin}
              >
                Iniciar sesión
              </ButtonLogin>
            </ContainerModal>
            <ContainerFooter>
              <Text>¿Aún no tienes cuenta?</Text>
              <a onClick={() => setModalShowCreateAccount(true)}>Registrate</a>
            </ContainerFooter>
          </Container>
        </Modal.Body>
      </Modal>
    </ModalStyle>
  )
}

export default Login
