import React, { useState } from "react"
import { Container, Nav, Navbar } from "react-bootstrap"
import SiteHeader from "./style"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import { useScrollSection } from "react-scroll-section"
import HeaderButton from "~sections/app/Header"
import { Login, CreateAccount, RecoverPassword } from "~components/Modals"
import { Images } from "~data"

const NavDesktop = () => {
  const [showScrolling, setShowScrolling] = useState(false)
  const [showReveal, setShowReveal] = useState(false)

  const [modalShow, setModalShow] = React.useState(false)
  const [modalShowCreateAccount, setModalShowCreateAccount] = React.useState(
    false
  )
  const [modalShowRecoverPassword, setModalShowRecoverPassword] = React.useState(
    false
  )

  const heroSection = useScrollSection("hero")
  const servicesSection = useScrollSection("services")
  const aboutSection = useScrollSection("about")
  const contentSection = useScrollSection("content")
  const preciosSection = useScrollSection("pricing")
  const promoSection = useScrollSection("promo")

  useScrollPosition(({ prevPos, currPos }) => {
    if (currPos.y < 0) {
      setShowScrolling(true)
    } else {
      setShowScrolling(false)
    }
    if (currPos.y < -300) {
      setShowReveal(true)
    } else {
      setShowReveal(false)
    }
  })

  return (
    <>
      <Login
        show={modalShow}
        onHide={() => setModalShow(false)}
        setModalShowCreateAccount={setModalShowCreateAccount}
        setModalShowRecoverPassword={setModalShowRecoverPassword}
        modalLeft={true}
      />
      <CreateAccount
        show={modalShowCreateAccount}
        onHide={() => setModalShowCreateAccount(false)}
        modalLeft={true}
      />
      <RecoverPassword
        show={modalShowRecoverPassword}
        onHide={() => setModalShowRecoverPassword(false)}
        modalLeft={true}
      />
      <SiteHeader
        className={`site-header site-header--menu-end light-header site-header--sticky ${
          showScrolling ? "scrolling" : ""
        } ${showReveal ? "reveal-header " : ""}`}
      >
        <Navbar expand="lg" bg="dark" variant="dark" className="site-navbar">
          <Container>
            <Navbar.Brand
              href="#home"
              onClick={heroSection.onClick}
              selected={heroSection.selected}
              className="brand-logo"
            >
              <img src={Images.FooterSix.Logo} alt="logo" className="imgLogo" />
              <SiteHeader.TitleLogo>GasGas Analytics</SiteHeader.TitleLogo>
            </Navbar.Brand>
            <div className="container-menu">
              <Nav className="mr-auto site-menu-main">
                <Nav.Link
                  className="nav-item"
                  href=""
                  onClick={heroSection.onClick}
                  selected={heroSection.selected}
                >
                  Inicio
                </Nav.Link>
                <Nav.Link
                  className="nav-item"
                  href=""
                  onClick={servicesSection.onClick}
                  selected={servicesSection.selected}
                >
                  Servicios
                </Nav.Link>
                <Nav.Link
                  className="nav-item"
                  href=""
                  onClick={aboutSection.onClick}
                  selected={aboutSection.selected}
                >
                  ¿Qué es?
                </Nav.Link>
                <Nav.Link
                  className="nav-item"
                  href=""
                  onClick={contentSection.onClick}
                  selected={contentSection.selected}
                >
                  Plataforma
                </Nav.Link>
                <Nav.Link
                  className="nav-item"
                  href=""
                  onClick={preciosSection.onClick}
                  selected={preciosSection.selected}
                >
                  Precios
                </Nav.Link>
                <Nav.Link
                  className="nav-item"
                  href=""
                  onClick={promoSection.onClick}
                  selected={promoSection.selected}
                >
                  Contacto
                </Nav.Link>
              </Nav>
              <HeaderButton
                className="d-none d-sm-flex"
                children="Iniciar sesion"
                onClick={() => setModalShow(true)}
              />
            </div>
          </Container>
        </Navbar>
      </SiteHeader>
    </>
  )
}

export default NavDesktop
