import styled from "styled-components/macro"
import {
  Box
} from '~styled';

 

const SiteHeader = styled.div.attrs({
  className: "site-header",
})`
  position: absolute;
  width: 100%;
  z-index: 99;
  top: 0;
  @media(max-width:992px){
    padding-top:13px;
    padding-bottom:13px;
  }
  
  .site-brand {
    order: 1;
  }
  .header-btns {
    order: 2;
  }
  .brand-logo{
   display: flex;
  }
  .imgLogo{
    width: 35px;
    height: 35px;
  }
  .container-menu{
    display: flex;
    position: relative;
    left: 40px;
  }
  .site-menu-main {
    
    .nav-item {
     
      color: #000 !important;
        font-size: 16px;
        font-weight: 400;
        font-style: normal;
        /* letter-spacing: -0.6px; */
        position: relative;
        @media (max-width: 992px) {
          border-bottom: 1px solid #ececec;
          padding-left: 20px;
          padding-right: 20px;
        }
        @media (min-width: 992px) {
          color: #fff;
          padding-top: 17px !important;
          padding-bottom: 15px !important;
          padding-left: 24px !important;
          padding-right: 24px !important;
        }
      
    }


  
  }
  

  .dropdown-toggle {
    @media (max-width: 992px) {
      display: flex !important;
      justify-content: space-between;
      min-width: 50px;
      display: block;
      width: 100%;
    }
    &[aria-expanded="true"]:after {
      @media (max-width: 992px) {
        content: "\f068";
      }
    }
    &:after {
      border: none;
      content: "\f078";
      font-family: "Font Awesome 5 free";
      font-weight: 700;
      font-size: 11px;
      position: relative;
      top: 1px;
      margin-left: 9px;
      transition: 0.4s;
      transform-origin: center;
      transform-box: view-box;
      @media (max-width: 992px) {
        margin-right: 9px;
        content: "\f067";
      }
    }
  }
  .main-nav-item.show {
    > .dropdown-toggle:after {
      @media (min-width: 992px) {
        transform: rotate(-180deg);
      }
    }
  }
  .site-navbar {
    @media (min-width: 992px) {
      position: static;
    }
  }
  .container-fluid {
    .site-navbar {
      @media (min-width: 768px) {
        padding-left: 10px;
        padding-right: 10px;
      }

      @media (min-width: 992px) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }

  .show {
    & > .dropdown-toggle {
      &:after {
        @media (max-width: 992px) {
          content: "\f068";
        }
      }
    }
    & > .dropdown-menu {
      @media (max-width: 992px) {
        display: block;
        margin-top: 0 !important;
      }
    }
  }

  &.site-header--sticky {
    &:not(.mobile-sticky-enable) {
      position: absolute !important;
      top: 0;
      right: 0;
      width: 100%;
      z-index: 999;

      @media (min-width: 992px) {
        position: fixed !important;
        transition: 0.4s;

        &.scrolling {
          transform: translateY(-100%);
          transition: 0.4s;
          .site-navbar {
            padding-top: 0;
            padding-bottom: 0;
          }
        }

        &.reveal-header {
          transform: translateY(0%);
          box-shadow: 0 12px 34px -11px rgba(65, 62, 101, 0.1);
          z-index: 1000;
        }
      }
      &.reveal-header{
        @media (min-width:992px){
          padding-top: 10px;
          z-index: 999;
          padding-bottom: 10px;
        }
      }
    }

    &.mobile-sticky-enable {
      top: 0;
      right: 0;
      width: 100%;
      z-index: 999;
      position: fixed !important;
      transition: 0.4s;
      &.scrolling {
        transform: translateY(-100%);
        transition: 0.4s;
      }

      &.reveal-header {
        transform: translateY(0%);
        box-shadow: 0 12px 34px -11px rgba(65, 62, 101, 0.1);
        z-index: 9999;
      }
    }
  }
  /* Light Header */
  &.light-header {
    .main-nav-item > .nav-link {
      color: #000 !important;
    }
    &.reveal-header {
      background: #fff;
    }
    .hamburgur {
      color: #000;
      border-color: #000;
    }
  }
  /* Dark Header */
  &.dark-header {
    &.reveal-header {
        background: #19191b;
    }
    &-2 {
      &.reveal-header {
      background: #416ff4;
      }
    }
  }

  &.site-header--menu-center {
    .navbar-collapse {
      @media (min-width:992px) {
        justify-content: center;
        margin-right: calc(-214px / 2);
      }
    }
  }
  &.site-header--menu-end {
    .navbar-collapse {
      justify-content: flex-end;
    }
  }
  &.site-header--menu-start {
    .container-fluid{
      .navbar-collapse {
        @media (min-width:992px){
          padding-left: 15px;
          margin-left: 3.125rem !important;
        }
      }
    }
  }

  /* Header Buttons */




&.site-header--menu-sep{
    @media (min-width: 992px){
      .navbar-collapse {
          margin-right: 20px;
          padding-right: 10px;
          position:relative;
          &:before {
            content: "";
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 2px;
            height: 26px;
            border-right: 2px solid rgba(130, 130, 136, 0.3);
          } 
      }
    }
  }
  &.site-header--button-sep{
      .header-btn-block{
        display:none;
      @media (min-width:480px){
        display:block;
        margin-left:auto;
        margin-right:20px;
      }
      @media (min-width:992px){
        margin-right:0px;
        margin-left:20px;
        &:before{
          content: "";
          position: absolute;
          left: -18px;
          top: 50%;
          transform: translateY(-50%);
          width: 2px;
          height: 26px;
          border-right: 2px solid rgba(130, 130, 136, 0.3);
        }
      }
    }
  }


&.site-header--with-border{
  .navbar-brand{
    @media (min-width:992px){
      display: flex;
      position:relative;
      &:before{
        content: "";
        position: absolute;
        right: -21px;
        top: 50%;
        transform: translateY(-50%);
        width: 2px;
        height: 26px;
        border-left: 2px solid rgba(38, 39, 41, 0.3);
        opacity:0;
      } 
    }
  }
}
&.site-header--services{
  .navbar-collapse{
    @media (min-width:992px){
      margin-right: 55px;
    }
  }
}
  
/* .site-header--service{
  @media (min-width:992px){
    background: #19191b;
  }
} */
  /* &.site-header--startup{
    positon
  } */
`

SiteHeader.TitleLogo = styled(Box)`
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 40px;
color: #25373F;
margin-left:10px;
`

export default SiteHeader
