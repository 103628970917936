import { Link } from '~components'
import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Images } from '~data'
import Footer from "./style"
export default function FooterFour(){
return(
    <Footer>
    <Container>
        <Footer.Box pb="30px" mt="100px" mb="100px">
        <Row className="justify-content-center justify-content-lg-between">
            <Col xs="12" className="col-7 col-xxs-4 col-lg-5 col-md-6 col-xs-4">
            <Footer.Logo>
                {/* Brand Logo*/}
                <Footer.Box mb="30px" mbMD="0">
                    <Link  to="#" style={{display:'flex'}}>
                        <img style={{width:35,height:35}} src={Images.FooterSix.Logo} alt="logo" />
                        <Footer.TitleLogo>GasGas Analytics</Footer.TitleLogo>
                    </Link>
                </Footer.Box>
            </Footer.Logo>
            </Col>
            <Col xs="12" className="col-lg-7 col-md-6">
                <Footer.Menu>
                <Footer.MenuItems><a href="#">Inicio</a></Footer.MenuItems>
                <Footer.MenuItems> <a href="#">¿Qué es?</a> </Footer.MenuItems>
                <Footer.MenuItems><a href="#">Blog</a></Footer.MenuItems>
                <Footer.MenuItems><a href="#">Contacto</a>
                </Footer.MenuItems>
                </Footer.Menu>
            </Col>
        </Row>
        </Footer.Box>
        <Footer.Copyright>
        <Footer.CopyrightText>© 2021 Plataforma Digital GasGas SA de CV</Footer.CopyrightText>
        <Footer.SocialShare>
            <Footer.SocialShareItem>
                <a href="https://www.facebook.com/gasgasapp">
                <i className="fab fa-facebook-square" />
                </a>
            </Footer.SocialShareItem>
            <Footer.SocialShareItem>
                <a href="https://www.twitter.com/gasgasapp">
                <i className="fab fa-twitter" />
                </a>
            </Footer.SocialShareItem>
            <Footer.SocialShareItem>
                <a href="https://www.instagram.com/gasgasapp">
                <i className="fab fa-instagram" />
                </a>
            </Footer.SocialShareItem>
            <Footer.SocialShareItem>
                <a href="https://www.linkedin.com/company/gasgas-app/">
                <i className="fab fa-linkedin" />
                </a>
            </Footer.SocialShareItem>
        </Footer.SocialShare>
        </Footer.Copyright>
    </Container>
    </Footer>
)
}