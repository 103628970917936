import React, { useState } from "react"
import {
  Container,
  FormControl,
  InputGroup,
  Modal,
} from "react-bootstrap"
import axios from 'axios';
import swal from 'sweetalert';
// import IntlTelInput from 'react-bootstrap-intl-tel-input'
import PhoneInput from 'react-phone-input-international'

import {
  ButtonLogin,
  ModalStyle,
  ContainerModal,
  ContainerFooter,
  Text,
  ContainerHeader,
  Alert,
  AlertSuccess,
} from "./style"
import "./styles.scss"
import { URL_SERVER } from "../../helpers"

const url = `${URL_SERVER}/public/user`;
const urlActivePhone = `${URL_SERVER}/twilio/verify-sms-code`;

const CreateAccount = props => {
  const { show, onHide, modalLeft = false } = props

  const cleanPhone = { code: 52, short: 'MX', phone: '' };
  const cleanState = { name: '', email: '', password: '', phone: cleanPhone };
  const cleanError = { ok: null, message: "" };

  const [state, setState] = useState(cleanState);
  const [error, setError] = useState(cleanError);
  const [disabled, setDisabled] = useState(false);
  const [activePhone, setActivePhone] = useState(false);

  function onClean() {
    setState(cleanState);
    setError(cleanError);
    setDisabled(false);
    setActivePhone(false);
    onHide();
  }

  async function onCreateAccount() {
    setError(cleanError);
    setDisabled(true);
    await axios.post(url, state)
    .then(({ data, status }) => {
      if (status !== 200) throw new Error(data?.statusText || errorText);
      if (!data.ok) throw new Error(data?.error?.message || errorText);
      swal("¡Usuario registrado con éxito!", "Ahora por favor capturé el código de verificación que te hemos enviado a tu teléfono.", "success");
      setActivePhone(true);
    })
    .catch(({ message }) => {
      if (/idx_user_email/g.test(message)) {
        setError({ ok: true, message: 'El correo ya está registrado' });
        return;
      }
      if (/idx_cellphone/g.test(message)) {
        setError({ ok: true, message: 'El teléfono ya está registrado' });
        return;
      }
      setError({ ok: true, message });
    });
    setDisabled(false);
  }

  async function onActivePhone() {
    setError(cleanError);
    setDisabled(true);
    const { phone, code } = state;
    await axios.post(urlActivePhone, { code, lada: `${phone?.code}`, cellphone: phone?.phone })
    .then(({ data, status }) => {
      if (status !== 200) throw new Error(data?.statusText || errorText);
      if (!data.ok) throw new Error(data?.error?.message || errorText);
      swal("¡Cuenta activada!", "Hemos activado tu cuenta correctamente, ya puedes iniciar sesión", "success");
      onClean();
    })
    .catch(({ message }) => {
      setError({ ok: true, message });
    });
    setDisabled(false);
  }

  return (
    <ModalStyle>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        contentClassName="ModalL"
        dialogClassName={modalLeft ? "modal-left" : ""}
      >
        <ContainerHeader>
            <span onClick={onHide}>x</span>
        </ContainerHeader>
        <Modal.Body>
          <Container>
            <div class="form-title text-center mb-4">
              <h4>Crear cuenta</h4>
            </div>

            {
              error.ok && (
                <Alert variant="danger">
                  {error.message}
                </Alert>
              )
            }

            {
              activePhone && (
                <AlertSuccess variant="success">
                  Su cuenta ha sido creada con éxito. Ahora por favor capturé el código de verificación que te hemos enviado a tu teléfono.
                </AlertSuccess>
              )
            }
            
            <InputGroup className="mb-3">
              <FormControl
                placeholder="Nombre completo..."
                aria-label="name"
                aria-describedby="Nombre"
                disabled={disabled || activePhone}
                value={state.name}
                onChange={({ target: { value }}) => setState({ ...state, name: value })}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <FormControl
                placeholder="Correo electrónico..."
                aria-label="email"
                aria-describedby="Correo electrónico"
                disabled={disabled || activePhone}
                value={state.email}
                onChange={({ target: { value }}) => setState({ ...state, email: value })}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <FormControl
                placeholder="Contraseña..."
                aria-label="Contraeña"
                type="password"
                aria-describedby="contraseña"
                disabled={disabled || activePhone}
                value={state.password}
                onChange={({ target: { value }}) => setState({ ...state, password: value })}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              {/* <IntlTelInput
                preferredCountries={['MX', 'US']}
                defaultCountry="MX"
                defaultValue={'+52 (55) 5555 5555'}
                disabled={disabled || activePhone}
                onChange={(phone) => {
                  const { alpha2, callingCode, phoneNumber, valid } = phone;
                  if (valid) {
                    setState({ ...state, phone: {
                      short: alpha2, code: Number((callingCode || "").replace(/\+/g, "")), phone: phoneNumber.replace(/ /g, ""),
                    }})
                  }
                }}
              /> */}
              <PhoneInput
                country={'mx'}
                disabled={disabled || activePhone}
                value={`${state.phone?.code}${state.phone?.phone}`}
                onChange={(value, country) => {
                  const { dialCode, countryCode } = country;
                  const rgx = new RegExp(`^${dialCode}`);
                  const phone = {
                    code: Number(dialCode),
                    short: (countryCode || '').toUpperCase(),
                    phone: `${value}`.replace(rgx, ''),
                  };
                  console.log(phone);
                  setState(st => ({ ...st, phone }));
                }}
              />
            </InputGroup>

            {
              activePhone && (
                <InputGroup className="mb-3">
                <FormControl
                  placeholder="Código de activación..."
                  aria-label="code"
                  aria-describedby="Códido de activación"
                  disabled={disabled}
                  value={state.code}
                  onChange={({ target: { value }}) => setState({ ...state, code: value })}
                />
              </InputGroup>
              )
            }

            <ContainerModal>
              <ButtonLogin
                className="btn-green"
                disabled={disabled}
                onClick={!activePhone ? onCreateAccount : onActivePhone}
              >
                { !activePhone ? "Crear cuenta" : "Activar cuenta" }
              </ButtonLogin>
            </ContainerModal>
            <ContainerFooter>
              <Text>¿Ya tienes cuenta?</Text>
              <a onClick={onClean}>Iniciar sesión</a>
            </ContainerFooter>
          </Container>
        </Modal.Body>
      </Modal>
    </ModalStyle>
  )
}

export default CreateAccount
