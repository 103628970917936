import React, { useState } from "react"
import { Container, FormControl, InputGroup, Modal } from "react-bootstrap"
import axios from 'axios';
import swal from 'sweetalert';
import {
  ButtonLogin,
  ModalStyle,
  ContainerModal,
  ContainerFooter,
  Text,
  ContainerHeader,
  Alert,
} from "./style"
import "./styles.scss"
import { URL_SERVER } from "../../helpers"

const url = `${URL_SERVER}/public/user/recover-password`;

const errorText = 'Ocurrió un error';

const Login = props => {
  const { onHide, modalLeft = false } = props

  const cleanState = { email: null };
  const cleanError = { ok: null, message: "" };

  const [state, setState] = useState(cleanState);
  const [error, setError] = useState(cleanError);
  const [disabled, setDisabled] = useState(false);

  function onClean() {
    setState(cleanState);
    setError(cleanError);
    setDisabled(false);
    onHide();
  }

  async function onRecoverPassword() {
    setError(cleanError);
    setDisabled(true);
    await axios.post(url, state)
    .then(({ data, status }) => {
      if (status !== 200) throw new Error(data?.statusText || errorText);
      if (!data.ok) throw new Error(data?.error?.message || errorText);
      swal("Te hemos envíado un correo", data?.data?.message || "Te recomendamos cambiar la contraseña una vez inicies sesión", "success");
      onClean();
    })
    .catch(({ message }) => {
      setError({ ok: true, message });
    });
    setDisabled(false);
  }

  return (
    <ModalStyle>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        contentClassName="ModalL"
        dialogClassName={modalLeft ? "modal-left" : ""}
      >
        <ContainerHeader>
          <span onClick={onHide}>x</span>
        </ContainerHeader>
        <Modal.Body>
          <Container>
            <div class="form-title text-center mb-4">
              <h4>Cambiar contraseña</h4>
            </div>
            {
              error.ok && (
                <Alert variant="danger">
                  {error.message}
                </Alert>
              )
            }
            <InputGroup className="mb-3">
              <FormControl
                placeholder="Correo electrónico..."
                aria-label="email"
                aria-describedby="Correo electrónico"
                disabled={disabled}
                value={state.email}
                onChange={({ target: { value }}) => setState({ ...state, email: value })}
              />
            </InputGroup>
            <ContainerModal>
              <ButtonLogin
                className="btn-green"
                disabled={disabled}
                onClick={onRecoverPassword}
              >
                Recuperar contraseña
              </ButtonLogin>
            </ContainerModal>
            <ContainerFooter>
              <Text>¿Ya tienes cuenta?</Text>
              <a onClick={onClean}>Iniciar sesión</a>
            </ContainerFooter>
          </Container>
        </Modal.Body>
      </Modal>
    </ModalStyle>
  )
}

export default Login
