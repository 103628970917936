import styled from "styled-components/macro"
import { Box, Heading, Button, Paragraph } from "~styled"

const ModalStyle = styled(Box)``

const ContainerModal = styled(Box)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  a {
    color: #0090fe !important;
    margin-bottom: 10px;
    cursor: pointer;
  }
`
const ContainerFooter = styled(Box)`
  padding: 1rem;
  border-top: 1px solid #e9ecef;
  margin-top: 35px;
  display: flex;
  justify-content: space-around;
  a {
    color: #0090fe !important;
    margin-bottom: 10px;
    cursor: pointer;
  }
`
const ContainerHeader = styled(Box)`
  font-size: 36px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  span {
    cursor: pointer;
  }
`

const Text = styled(Paragraph)`
  font-size: 18px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 32px;
  margin-bottom: 10px;
`

const ButtonLogin = styled(Button)`
  width: 180px;
  min-width: 130px;
  height: 50px;
  border-radius: 500px;
  width:20%
  font-size: 14px;
  @media (min-width: 480px) {
    min-width: 180px;
    height: 56px;
    font-size: 15px;
  }

  &.🌱 {
    margin-right: 18px;
    margin-bottom: 20px;
  }

  &.btn-red {
    color: #fff;
    background-color: #ff7272;
    border-color: #ff7272;
    &:hover {
      color: #fff;
      background-color: #ff8787;
      border-color: #ff8080;
    }
  }
  &.btn-green {
    color: #fff;
    background-color: #099a97;
    border-color: #099a97;
    &:hover {
      color: #fff;
      background-color: #3aaeac;
      border-color: #22a4a1;
    }
  }
`

const Alert = styled(Box)`
  color: #842129;
  background-color: #f7d8da;
  border-color: #842129;
  text-align: center;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 5px;
  height: auto;
`

const AlertSuccess = styled(Box)`
  color: #155724;
  background-color: #d4edda;
  border-color: #155724;
  text-align: center;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 5px;
  height: auto;
`

export {
  ButtonLogin,
  ModalStyle,
  ContainerModal,
  ContainerFooter,
  Text,
  ContainerHeader,
  Alert,
  AlertSuccess,
}
