import React from 'react'
import SectionTitle from './components/SectionTitle'
import { StaticImage as Img } from 'gatsby-plugin-image'
import { Col, Container, Row } from 'react-bootstrap'
import Widget from './components/Widget'
import Content from './style'
export default function ContentSectionOne(){
return(
<Content backgroundColor="#f2f5fb">
  <Container>
    <Row className="align-items-center justify-content-center mt-5 mb-5">
      {/* about-us Content */}
      <Col className="col-xxl-6 col-lg-7 col-md-9 col-xs-10 order-2 orde-lg-1">
        <Content.Box mbLG="60px" mb="30px">
        <SectionTitle
        subTitle="Plataforma Web" 
        title="Administra y cancela en cualquier momento"
        text="Con nuestra plataforma web podrás administrar tus sucripciones, pagos, reportes y cuentas de analistas para tu empresa."
        subTitleProps={{mb:"20px"}}
        titleProps={{mb:"10px"}}
        />
        </Content.Box>
        <Content.Box>
          <Row className=" justify-content-center">
            <Col className="col-lg-6 col-xs-6">
              <Widget icon="far fa-envelope" title="Usuarios analistas" text="Creo usuarios con permisos en <br class='d-none d-xl-block'> base a tus necesidades" />
            </Col>
            <Col className="col-lg-6 col-xs-6">
            <Widget icon="fa fa-layer-group" title="Sistema User Friendly" text="Lo que necesitas cuando lo <br class='d-none d-xl-block'> necesitas" />
            </Col>
          </Row>
        </Content.Box>
      </Col>
      {/*/ .about-us Content */}
      <Col xs="12" className="col-xxl-6 col-lg-5 col-md-8 col-xs-10 order-1 order-lg-2">
        <Content.ImageGroup>
        <Img src="../../../assets/image/home-app/contentMobile.png" alt="content" layout="fullWidth" placeholder="blurred"/>
          <Content.ImageShape>
            <Img src="../../../assets/image/home-app/red-shape.png" alt="content" layout="fullWidth" placeholder="blurred"/>
          </Content.ImageShape>
        </Content.ImageGroup>
      </Col>
    </Row>
  </Container>
</Content>

)
}