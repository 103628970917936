import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import SectionTitle from './components/SectionTitle'
import Widget from './components/widget'
import { StaticImage as Img } from 'gatsby-plugin-image'
import About from './style'
export default function AboutSection(){
return(
<About backgroundColor="#f2f5fb">
  <Container>
    <Row className="justify-content-center">
      <Col className="col-xl-7 col-lg-9 col-md-10 col-xs-10 text-center">
        <SectionTitle 
        subTitle="¿Qué es?" 
        title="Recibe notificaciones de cambio 
        <br class='d-none d-xs-block d-lg-none d-xl-block'>
        de precio de tu competencia"
        titleProps={{mb:"50px"}}
        subTitleProps={{mb:"15px"}}
        />
      </Col>
    </Row>
    <Row className="align-items-center justify-content-center">
      <Col className="col-xxl-3 col-lg-4 col-md-6 col-sm-8 col-xs-9 order-2 order-lg-1">
        <Widget directionXS="row-reverse" title="Plataforma web" icon="fa fa-layer-group" text="Administra todos tus servicios desde una plataforma web "/>
        <Widget directionXS="row-reverse" title="Reportes" icon="fa fa-chart-pie" text="Obten reportes personalizados automático en Excel"/>
        <Widget directionXS="row-reverse" title="Configuración" icon="fa fa-cog" text="Configura tu cuenta y da acceso a analistas"/>
      </Col>
      <Col xs="12" className="col-xxl-6 col-lg-4 col-md-8 col-xs-9 order-1 order-lg-2 text-center">
        <About.ImageContent>
          <About.Image>
            <Img  src="../../../assets/image/home-app/hero1.png" alt="content" layout="fullWidth" placeholder="blurred"/>
          </About.Image>
          <About.Shape>
            <Img src="../../../assets/image/home-app/green-shape.png" alt="content" layout="fullWidth" placeholder="blurred"/>
          </About.Shape>
        </About.ImageContent>
      </Col>
      <Col className="col-xxl-3 col-lg-4 col-md-6 col-sm-8 col-xs-9 order-3">
      <Widget directionXS="row" title="Agregar tus favoritos" icon="fa fa-bookmark" text="Sistema personalizado para monitorear lo realmente importante"/>
        <Widget directionXS="row" title="Servicio al gasolinero" icon="fa fa-headphones" text="Servicio personalizado vía whatsapp"/>
        <Widget directionXS="row" title="Alertas personalizadas" icon="fa fa-bell" text="Enciende o apaga tus alertas en cualquier momento"/>
      </Col>
    </Row>
  </Container>
</About>

)
}