import React from "react"
import { PopupButton } from "react-calendly"
import WhatsAppWidget from "react-whatsapp-widget"
import "react-whatsapp-widget/dist/index.css"
import { PageWrapper } from "~components/Core"
import HeroSection from "~sections/app/Hero"
import HeaderButton from "~sections/app/Header"
import ServicesSection from "~sections/app/Services"
import AboutSection from "~sections/app/About"
import ContentSectionOne from "~sections/app/ContentOne"
import PricingSection from "~sections/app/Pricing"
import TestimonialSection from "~sections/app/Testimonial"
import PromoSection from "~sections/app/Promo"
import CtaSection from "~sections/app/Cta"
import FooterFour from "~sections/app/FooterFour"
import Nav from "../components/Nav"
import "./styles.scss"
import { ScrollingProvider, Section } from "react-scroll-section"

import 'react-phone-input-international/lib/style.css';

export default function HomeApp() {
  return (
    <>
      <ScrollingProvider>
        <Nav />
        <Section id="hero">
          <HeroSection />
        </Section>
        <Section id="services">
          <ServicesSection />
        </Section>
        <Section id="about">
          <AboutSection />
        </Section>
        <Section id="content">
          <ContentSectionOne />
        </Section>
        <Section id="pricing">
          <PricingSection />
        </Section>
        <Section id="promo">
          <PromoSection />
        </Section>
        <FooterFour />∏
      </ScrollingProvider>

      <WhatsAppWidget
        sendButton="Enviar"
        placeholder="Escribe un mensaje"
        textReplyTime="Normalmente responde en 10 minutos"
        message="Hola, ¿cómo podemos ayudarte hoy?"
        companyName="Alertas GasGas"
        phoneNumber="+528123322741"
      />
    </>
  )
}
