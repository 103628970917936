import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { StaticImage as Img } from "gatsby-plugin-image"
import { Link } from "~components"

import Hero from "./style"
import { Login, CreateAccount, RecoverPassword } from "~components/Modals"
export default function HeroSection() {
  const [modalShow, setModalShow] = React.useState(false)
  const [modalShowCreateAccount, setModalShowCreateAccount] = React.useState(
    false
  )
  const [modalShowRecoverPassword, setModalShowRecoverPassword] = React.useState(
    false
  )

  return (
    <>
      <Login
        show={modalShow}
        onHide={() => setModalShow(false)}
        setModalShowCreateAccount={setModalShowCreateAccount}
        setModalShowRecoverPassword={setModalShowRecoverPassword}
      />
      <CreateAccount
        show={modalShowCreateAccount}
        onHide={() => setModalShowCreateAccount(false)}
      />
      <RecoverPassword
        show={modalShowRecoverPassword}
        onHide={() => setModalShowRecoverPassword(false)}
      />
      <Hero backgroundColor="rgba(21, 205, 168, 0.2)">
        <Container>
          <Row className="align-items-center justify-content-center mt-5">
            {/* Welcome content Area */}
            <Col className="col-xxl-6 col-lg-7 col-md-9 col-xs-11 order-2 order-lg-1">
              <Hero.Content>
                <Hero.SubTitle fontColor="#ff7272" as="h3">
                  ¡Comienza ahora!
                </Hero.SubTitle>
                <Hero.Title as="h1">
                  Monitorea el
                  <br className="d-xs-block d-lg-none d-xl-block" />
                  mercado gasolinero
                  <br className=" d-xs-block d-lg-none d-xl-block" />
                  desde MXN$99
                </Hero.Title>
                <Hero.Text fontColor="rgba(38, 39, 41, 0.7)">
                  Recibe vía whatsapp los cambios de precio de
                  <br className="d-none d-md-block" />
                  {' '}tu competencia y acelera tu toma de decisiones.
                </Hero.Text>
                <Hero.ButtonGroup>
                  {/* <Hero.Button className="btn-red 🌱" as={Link} to="/">Watch Video</Hero.Button> */}
                  <Hero.Button
                    className="btn-green"
                    onClick={() => setModalShow(true)}
                  >
                    Iniciar sesión
                  </Hero.Button>
                </Hero.ButtonGroup>
              </Hero.Content>
            </Col>
            {/*/ .Welcome Content Area */}
            {/*/ .Welcome img Area */}
            <Col
              xs="12"
              className="col-xxl-6 col-lg-5 col-md-8 col-xs-10 order-1 order-lg-2 ml-5"
            >
              <Hero.ImageGroup>
                <Hero.ImageOne>
                  <Img
                    className="w-100"
                    src="../../../assets/image/home-app/hero1.png"
                    alt="app"
                    layout="fullWidth"
                    placeholder="blurred"
                  />
                </Hero.ImageOne>
                <Hero.ImageTwo>
                  <Img
                    className="w-100"
                    src="../../../assets/image/home-app/hero2.png"
                    alt="app-img"
                    layout="fullWidth"
                    placeholder="blurred"
                  />
                </Hero.ImageTwo>
              </Hero.ImageGroup>
            </Col>
            {/*/ .Welcome img Area */}
          </Row>
        </Container>
      </Hero>
    </>
  )
}
