import React from "react"
import classNames from "classnames"
import "./MobileNavLinks.scss"
import { useScrollSection } from "react-scroll-section"
import HeaderButton from "~sections/app/Header"

const MobileNavLinks = ({ open,setMobileNavOpen }) => {
  const heroSection = useScrollSection("hero")
  const servicesSection = useScrollSection("services")
  const aboutSection = useScrollSection("about")
  const contentSection = useScrollSection("content")
  const preciosSection = useScrollSection("pricing")
  const promoSection = useScrollSection("promo")

  const displayMobileNavLinks = classNames("mobile-nav-wrapper", {
    open: open,
  })

  return (
    <nav arai-label="mobile navigation" className={displayMobileNavLinks}>
      <a
        className="mobile-link"
        onClick={() => {
          setMobileNavOpen(mobileNavOpen => !mobileNavOpen)
          heroSection.onClick()
        }}
        selected={heroSection.selected}
      >
        Inicio
      </a>
      <a
        className="mobile-link"
        onClick={() => {
          setMobileNavOpen(mobileNavOpen => !mobileNavOpen)
          servicesSection.onClick()
        }}
        selected={servicesSection.selected}
      >
        Servicios
      </a>
      <a
        className="mobile-link"
        onClick={() => {
          setMobileNavOpen(mobileNavOpen => !mobileNavOpen)
          aboutSection.onClick()
        }}
        selected={aboutSection.selected}
      >
        ¿Qué es?
      </a>
      <a 
        className="mobile-link"
        onClick={() => {
          setMobileNavOpen(mobileNavOpen => !mobileNavOpen)
          contentSection.onClick()
        }}
        selected={contentSection.selected}
      >
        Plataforma
      </a>
      <a
        className="mobile-link"
        onClick={() => {
          setMobileNavOpen(mobileNavOpen => !mobileNavOpen)
          preciosSection.onClick()
        }}
        selected={preciosSection.selected}
      >
        Precios
      </a>
      <a
        className="mobile-link"
        onClick={() => {
          setMobileNavOpen(mobileNavOpen => !mobileNavOpen)
          promoSection.onClick()
        }}
        selected={promoSection.selected}
      >
        Contacto
      </a>
      <HeaderButton className="d-none d-sm-flex" children="Iniciar sesion" />
    </nav>
  )
}

export default MobileNavLinks
